angular.module('org-admin')

  .directive('amountInfo', function() {

    return {
      scope: {},
      bindToController: {
        formName: '<',
        invoiceAmountChanged: '=',
        invoiceGroup: '=',
        processingFee: '=',
        transactionFee: '='
      },
      controllerAs: 'ctrl',
      templateUrl: '/static/org/send-invoices/amount-info.html',
      controller: function(
        $debounce,
        $filter,
        $timeout,
        Alerts,
        appPermission,
        currentOrg,
        FinancialAdminUiService,
        ItemVariationService,
        i18ng
      ) {
        var ctrl = this
        ctrl.org_id = currentOrg.id
        ctrl.orgItemVariationsLoaded = false
        ctrl.orgHasItemVariations = false
        ctrl.itemVariationSelectShowing = true
        ctrl.showSaleItemModal = false
        ctrl.showTaxCode = false

        ItemVariationService
          .count({
            params: {
              organization_id: ctrl.org_id
            }
          })
          .then(function(resp) {
            var itemVariationCount = resp.data.result && resp.data.result.total_records
            ctrl.orgHasItemVariations = itemVariationCount > 0
            ctrl.orgItemVariationsLoaded = true
          })

        ctrl.$onInit = function() {
          FinancialAdminUiService.loadElementScript('saleItem')
          if (!ctrl.invoiceGroup.itemized) { createBillAmountLineItem() }
          ctrl.showTaxCode = appPermission.store.taxable
          ctrl.watchEvents()
        }

        ctrl.watchEvents = function() {
          var element = document.getElementById('sale-item-element')
          element.addEventListener('closeSaleItemModal', function(event) {
            ctrl.toggleItemModal(event.detail)
          })

          element.addEventListener('success', function(event) {
            ctrl.onSaleItemCreateSuccess(event.detail)
          })
        }

        ctrl.toggleItemization = function() {
          ctrl.invoiceGroup.itemized = !ctrl.invoiceGroup.itemized
          ctrl.invoiceGroup.removeAllLineItems()

          if (!ctrl.invoiceGroup.itemized) {
            createBillAmountLineItem()
          }
        }

        ctrl.onSaleItemCreateSuccess = function(item) {
          Alerts.success('ADD_SALE_ITEM_MODAL.toast_success', {
            saleItemName: item.name
          })
          var newItemVariation = item.variations[0]
          ctrl.addInvoiceGroupItem(newItemVariation)
          ctrl.orgHasItemVariations = true
        }

        ctrl.addInvoiceGroupItem = function(itemVariation) {
          ctrl.invoiceGroup.addLineItem({
            item_variation_id: itemVariation.id,
            name: itemVariation.full_name,
            quantity: 1,
            price: itemVariation.price || '0.00',
            price_cents: itemVariation.price_cents || 0,
            upfront_amount: itemVariation.upfront_amount,
            upfront_amount_cents: itemVariation.upfront_amount_cents
          })
          ctrl.invoiceChanged()
        }

        ctrl.toggleItemModal = function(event) {
          ctrl.showSaleItemModal = !ctrl.showSaleItemModal
        }

        ctrl.removeInvoiceGroupItem = function(invoiceGroupItem) {
          ctrl.invoiceGroup.removeLineItem(invoiceGroupItem)
          ctrl.invoiceChanged()
        }

        ctrl.invoiceChanged = function() {
          ctrl.invoiceAmountChanged = true
        }

        ctrl.showRecipientFees = function() {
          return appPermission.powerPay.usable || appPermission.orgAdmin.usable
        }

        ctrl.upfrontAmount = function(item) {
          if (item.price_cents === item.upfront_amount_cents) {
            return i18ng.t('SEND_INVOICES.full_price')
          }
          else {
            var amount = item.upfront_amount_cents * item.quantity / 100
            return $filter('currency')(amount, currentOrg.currency)
          }
        }

        ctrl.totalUpfrontAmount = function() {
          var totalUpfrontAmount = ctrl.invoiceGroup.totalUpfrontAmount()
          if (ctrl.invoiceGroup.total() === totalUpfrontAmount) {
            return i18ng.t('SEND_INVOICES.full_price')
          }
          else {
            return $filter('currency')(totalUpfrontAmount, currentOrg.currency)
          }
        }

        ctrl.applyItemVariationSelection = function(itemVariationId) {
          if (!itemVariationId) return
          ItemVariationService
            .find(itemVariationId)
            .then(function(itemVariation) {
              ctrl.addInvoiceGroupItem(itemVariation)
              reinitializeSelect2()
            })
        }

        ctrl.itemVariationSelect2Options = {
          ajax: {
            transport: $debounce(function(params, success, failure) {
              var requestParams = {
                organization_id: ctrl.org_id,
                page: params.data.page || 1,
                per_page: 10,
                'order[full_name]': 'asc',
                membership: false
              }

              if (params.data.term) {
                requestParams.search_fields = 'full_name'
                requestParams.search_term = params.data.term
              }

              ItemVariationService
                .findAll(requestParams)
                .then(success, failure)
            }, 250),
            processResults: function(itemVariations) {
              var selectedItemVariationIds = _.pluck(ctrl.invoiceGroup.invoice_group_items, 'item_variation_id')
              var formattedItemVariations = _.map(itemVariations, function(itemVariation) {
                var disableOption = _.contains(selectedItemVariationIds, itemVariation.id)

                return {
                  id: itemVariation.id,
                  text: itemVariation.full_name,
                  disabled: disableOption
                }
              })

              return {
                results: formattedItemVariations,
                pagination: { more: !itemVariations.pagination.last_page }
              }
            },
            cache: false
          }
        }

        function createBillAmountLineItem() {
          if (ctrl.invoiceGroup.invoice_group_items.length >= 1) return
          // non-itemized invoice groups need a line item for the bill total
          var invoiceGroupBillAmountItem = { name: 'Bill Amount', price: '' }
          ctrl.invoiceGroup.addLineItem(invoiceGroupBillAmountItem)
        }

        function reinitializeSelect2() {
          // Reinitialize the select2 using angular
          // to clear previously rendered options
          // between ajax requests
          ctrl.itemVariationSelectValue = ''
          ctrl.itemVariationSelectShowing = false
          $timeout(function() {
            ctrl.itemVariationSelectShowing = true
          })
        }
      }
    }
  })
