angular.module('org-admin')
  .component('learnMore', {
    templateUrl: '/static/org/learn-more/learn-more.html',
    controller: function(
      $filter,
      $route,
      $routeParams,
      $window,
      $scope,
      $rootScope,
      $sce,
      appPermission,
      setAs,
      currentOrg,
      currentUser,
      billingAccountsService,
      pageViewHandler
    ) {

      var $ctrl = this
      var features = {
        registration: {
          iframeSrc: 'https://cloud.se.sportsengine.com/HQ-registration.html'
        },
        competition: {
          iframeSrc: 'https://cloud.se.sportsengine.com/HQ-competition.html'
        },
        website: {
          iframeSrc: 'https://cloud.se.sportsengine.com/HQ-website.html'
        },
        safety: {
          iframeSrc: 'https://cloud.se.sportsengine.com/HQ-Upsell-Page-Safety'
        },
        billing: {
          iframeSrc: 'https://cloud.se.sportsengine.com/HQ-Invoicing.html',
          headerTitleTranslationKey: 'LEARN_MORE.FINANCIALS.header_title',
          headerLinkContext: appPermission.financialSettingsUi.usable ? 'financialSettings' : 'financialProvisioningRestriction',
          headerLinkTranslationKey: 'LEARN_MORE.FINANCIALS.header_link_text'
        }
      }

      $window.addEventListener('message', onPostMessage, false)
      $scope.$on('$destroy', function() { $window.removeEventListener('message', onPostMessage, false) })

      $ctrl.hasHeader = function() {
        return $ctrl.headerTitleTranslationKey || $ctrl.headerLinkTranslationKey
      }

      var billingAccount

      currentUser.getSelfPersona().then(loadBillingAccount)

      function loadBillingAccount() {
        billingAccountsService.fetch().then(function(data) {
          billingAccount = data
        }).then(loadComplete)
      }

      function loadComplete() {
        $rootScope.$on('$routeChangeSuccess', initLearnMorePage)
        if ($route.current) initLearnMorePage()
      }

      function initLearnMorePage() {
        pageViewHandler.addPageDepths({ depth2: $filter('titleCase')($routeParams.feature) })
        var feature = features[$routeParams.feature]
        $ctrl.iframeSrc = $sce.trustAsResourceUrl(feature.iframeSrc + '?' + getParams())
        $ctrl.headerLinkTranslationKey = feature.headerLinkTranslationKey
        $ctrl.headerTitleTranslationKey = feature.headerTitleTranslationKey
        $ctrl.headerLinkContext = feature.headerLinkContext
      }

      function getParams() {
        return angular.element.param({
          first_name: currentUser.firstname,
          last_name: currentUser.lastname,
          email: currentUser.selfPersona.owner_primary_email,
          org_name: currentOrg.name,
          salesforce_id: billingAccount.salesforce_crm_id
        })
      }

      function onPostMessage(event) {
        var data = event.data || {}
        if (data.type === 'frame:loading') pageLoaded(false)
        if (data.type === 'frame:loaded') pageLoaded(true)
        if (data.type === 'learnMoreClick') pageViewHandler.fireEvent('LearnMoreClick', 1)
        if (data.type === 'upgradeClick') pageViewHandler.fireEvent('UpgradeClick', 1)
      }

      function pageLoaded(isLoaded) {
        $rootScope.safeApply(setAs($ctrl, 'loaded', isLoaded))
        if (!isLoaded) return
        pageViewHandler.firePageView()
      }
    }
  })


  // Code for the landing pages (to be installed by design)
  // ;(function(){
  //
  //   window.addEventListener('load', postMessage('frame:loaded'))
  //   window.addEventListener('beforeunload', postMessage('frame:loading'))
  //
  //   // If you modify this to pass anything sensitive, you will need specific domains (instead of *) to avoid security holes.
  //   function postMessage(type) {
  //     return function(e) {
  //       try { window.parent.postMessage({ type: type }, '*'); } catch(e) {}
  //     }
  //   }
  //
  // })();
  //
  // Code for the LearnMore buttons (to be installed by design)
  // onclick = window.parent.postMessage({type: 'learnMoreClick'}, '*')
