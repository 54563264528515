angular.module('pl-shared')
  .component('adminNav', {
    templateUrl: '/static/shared/admin-nav/admin-nav.html',
    controllerAs: '$ctrl',
    controller: function(_, $scope, $route, HqMenu, currentOrg, ENV) {
      var $ctrl = this
      $ctrl.loading = true
      $ctrl.selectedItemPath = ''

      $scope.listenToRoot('$locationChangeSuccess', function() {
        $ctrl.setSelectedItem()
      })

      $ctrl.setSelectedItem = function() {

        var item = findSelectedItem()

        if (item) {
          selectItem(item)
          return
        }

        // fallback - can we make a reasonable assumption about a parent item?
        var fallbackSuccess = attemptFallbackForSelectedItem()

        // if we couldn't find a fallback, don't show any selected state (rather than showing an incorrect one)
        if (!fallbackSuccess) {
          // this won't do quite what is expected until left-nav 3.5.0
          $ctrl.selectedItemPath = ''
        }

      }

      loadAdminControlPanel()

      function loadAdminControlPanel() {
        return HqMenu.get(currentOrg.id)
          .then(function(response) {
            $ctrl.navTree = _.get(response, 'data.nav_tree')
            $ctrl.footerLinks = footerLinks(response)
            $ctrl.logoUrl = _.get(response, 'data.org.logo.is_default') ? null : (_.get(response, 'data.org.logo.large_logo_url') || _.get(response, 'data.org.logo.url'))
            $ctrl.name = _.get(response, 'data.org.name')
            $ctrl.loading = false
            initNavTree($ctrl.navTree)
            $ctrl.setSelectedItem()
          })
      }

      function footerLinks(response) {
        return _.get(response, 'data.footer_links') || []
      }

      function initNavTree(navItems) {
        _.forEach(navItems, function(navItem) {
          if (navItem.secondaryItems) {
            initNavTree(navItem.secondaryItems)
            return
          }

          if (ENV.ORG_URL) {
            // If ORG_URL is defined on the ENV constant then it will be used in place of the sn_frontend domain here
            // If/when a new permanent domain + path is decided on this can be changed in the HqMenu service and this can be removed
            // Or it can be used instead to handle situations like the dev+staging env noted above
            navItem.attributes.href = navItem.attributes.href.replace(ENV.urls.snFrontend, ENV.ORG_URL)
          }

          var navItemUrl = new URL(navItem.attributes.href)
          var navItemPath = navItemUrl.href.substring(navItemUrl.origin.length)
          navItem.route = _.find($route.routes, function(route) {
            return route.regexp && route.regexp.test(navItemPath)
          })

        })
      }

      function attemptFallbackForSelectedItem() {

        var currentContext = $route.current.context
        if (!currentContext) {
          return false
        }

        // simplify the context to try to handle sub pages
        // takes app.billing.programs.active and reduces to app.billing.programs
        // will catch some common patterns (but not every scenario)
        var contextPieces = currentContext.split('.')
        while (contextPieces.length > 2) {
          contextPieces.pop()
          var item = findSelectedItem(contextPieces.join('.'))
          if (item) {
            selectItem(item)
            return true
          }
        }

      }

      function findSelectedItem(routeContext) {

        var currentContext = (routeContext) ? routeContext : $route.current.context
        if (!currentContext || !$ctrl.navTree) {
          return
        }

        // learn more pages are unique, set those selected states differently
        var isLearnMorePage = (currentContext === 'app.billing.learn_more')

        for (var i = 0; i < $ctrl.navTree.length; i++) {
          var navItem = $ctrl.navTree[i]
          if (navItem.secondaryItems) {
            for (var j = 0; j < navItem.secondaryItems.length; j++) {
              var secondaryItem = navItem.secondaryItems[j]
              if (isSelectedItem(secondaryItem, currentContext, isLearnMorePage)) {
                selectItem(secondaryItem)
                return secondaryItem
              }
            }
          }
          else if (isSelectedItem(navItem, currentContext, isLearnMorePage)) {
            selectItem(navItem)
            return navItem
          }
        }

        return undefined

      }

      function selectItem(item) {
        $ctrl.selectedItemPath = item.attributes.href
      }

      function isSelectedItem(item, currentContext, isLearnMorePage) {

        if (isLearnMorePage) {
          var feature = $route.current.params.feature
          feature = (feature === 'billing') ? 'financials' : feature
          return (item.key === feature)
        }

        // does this particular item have some kind of override?
        if ($route.current.leftNavKey && item.key === $route.current.leftNavKey) {
          return true
        }

        // assuming this item has a route/context, then check by that
        if (!item.route || !item.route.context) {
          return false
        }

        // app.billing.programs.active
        return (currentContext.includes(item.route.context))

      }

    }
  })
