(function() {

  angular.module('org-admin')
    .config(function($routeProvider) {

      var routes = {
        // first to capture all learn more pages, regardless of section
        '/org/:currentOrgId/:feature/learn_more': {
          context: 'app.billing.learn_more',
          title: 'ROUTE_TITLE.learn_more',
          pageDepths: { depth1: 'HQ', depth2: '', depth3: 'LearnMore' },
          skipPageView: true
        },
        '/org/:currentOrgId/registration_request': {
          context: 'app.iframeContent.registrationRequest',
          iframeContent: 'registrationRequest',
          title: 'ROUTE_TITLE.registration_request',
          pageDepths: { depth1: 'HQ', depth2: 'RegistrationRequest' }
        },
        '/org/:currentOrgId/orders': {
          appPermission: 'orders',
          context: 'app.billing.orders',
          title: 'ROUTE_TITLE.orders',
          pageDepths: { depth1: 'HQ', depth2: 'Orders' },
          skipPageView: true
        },
        '/org/:currentOrgId/orders/:orderId/detail': {
          appPermission: 'orders',
          context: 'app.billing.orders.detail',
          title: 'ROUTE_TITLE.order_detail',
          pageDepths: { depth1: 'HQ', depth2: 'Orders', depth3: 'SaleDetail' }
        },
        '/org/:currentOrgId/orders/:orderId': {
          appPermission: 'orders',
          redirectTo: '/org/:currentOrgId/orders/:orderId/detail'
        },
        '/org/:currentOrgId': {
          context: 'app.billing.home',
          title: 'ROUTE_TITLE.home',
          pageDepths: { depth1: 'HQ', depth2: 'Dashboard' }
        },
        // Governing season affiliations routes
        '/org/:currentOrgId/affiliations/:affiliateOrgId/eligibility/:eligibilityRuleSetId/persona/:personaId': {
          redirectTo: '/org/:currentOrgId/affiliations/:affiliateOrgId/eligibility/:eligibilityRuleSetId/active/persona/:personaId'
        },
        '/org/:currentOrgId/affiliations/:affiliateOrgId/eligibility/:eligibilityRuleSetId': {
          redirectTo: '/org/:currentOrgId/affiliations/:affiliateOrgId/eligibility/:eligibilityRuleSetId/active'
        },
        '/org/:currentOrgId/affiliations/:affiliateOrgId/eligibility/:eligibilityRuleSetId/:status': {
          appPermission: 'affiliateEligibility',
          context: 'app.billing.affiliate.eligibility',
          title: 'ROUTE_TITLE.eligibility',
          pageDepths: { depth1: 'HQ', depth2: 'Memberships', depth3: 'MembershipEligiblity', depth4: '' }
        },
        '/org/:currentOrgId/affiliations/:affiliateOrgId/eligibility/:eligibilityRuleSetId/:status/persona/:personaId': {
          appPermission: 'affiliateEligibility',
          context: 'app.billing.affiliate.eligibility.detail',
          title: 'ROUTE_TITLE.eligibility',
          pageDepths: { depth1: 'HQ', depth2: 'Memberships', depth3: 'MembershipEligiblity', depth4: '', depth5: 'PersonaDetails' }
        },
        '/org/:currentOrgId/affiliations/:affiliateOrgId/governing_seasons': {
          appPermission: 'governingSeasons',
          context: 'app.billing.governingSeasons.affiliate',
          title: 'ROUTE_TITLE.GOVERNING_SEASONS.affiliate',
          pageDepths: { depth1: 'HQ', depth2: 'GoverningSeasons', depth3: '', depth4: 'List' }
        },
        '/org/:currentOrgId/affiliations/:affiliateOrgId/governing_seasons/:seasonId': {
          appPermission: 'governingSeasonAffiliate',
          context: 'app.billing.governingSeasons.affiliate.detail',
          title: 'ROUTE_TITLE.GOVERNING_SEASONS.affiliate',
          skipPageView: true
        },
        '/org/:currentOrgId/affiliations/:affiliateOrgId/memberships': {
          appPermission: 'affiliateMemberships',
          context: 'app.billing.memberships.view',
          title: 'ROUTE_TITLE.memberships_current',
          pageDepths: { depth1: 'HQ', depth2: 'Memberships', depth3: 'PublishedMemberships' }
        },
        '/org/:currentOrgId/affiliations/:affiliateOrgId/memberships/club-assignments': {
          context: 'app.billing.affiliate.memberships.clubAssignments',
          title: 'ROUTE_TITLE.club_assignments',
          pageDepths: { depth1: 'HQ', depth2: 'Memberships', depth3: 'PublishedMemberships', depth4: 'ClubAssignments' }
        },
        '/org/:currentOrgId/affiliations/:affiliateOrgId/memberships/:membershipDefinitionId/manage-invites': {
          context: 'app.billing.affiliate.memberships.manageInvites',
          title: 'ROUTE_TITLE.manage_invites',
          pageDepths: { depth1: 'HQ', depth2: 'Memberships', depth3: '', depth4: 'ManageInvites' }
        },
        // SNF Directory affiliations routes
        '/org/:currentOrgId/affiliations/:classification': {
          appPermission: 'profiles',
          context: 'app.billing.affiliations.classification',
          title: 'ROUTE_TITLE.affiliation_list',
          reloadOnSearch: false,
          pageDepths: { depth1: 'HQ', depth2: 'Directory', depth3: 'Affiliations', depth4: '' }
        },
        '/org/:currentOrgId/affiliations/:classification/:childOrgId': {
          appPermission: 'profiles',
          context: 'app.billing.affiliations.classification.detail',
          title: 'ROUTE_TITLE.affiliation_detail',
          pageDepths: { depth1: 'HQ', depth2: 'Directory', depth3: 'Affiliations', depth4: '', depth5: 'Affiliate' }
        },
        // Directory & SE Directory UI affiliations routes
        // * existing SNF directory route
        '/org/:currentOrgId/directory': {
          appPermission: 'profiles',
          redirectTo: '/org/:currentOrgId/people'
        },
        // * future SE Directory UI directory route (not built yet)
        // '/org/:currentOrgId/directory': {
        //   appPermission: 'platformPrograms',
        //   context: 'app.iframeContent.directory',
        //   iframeContent: 'directory',
        //   title: 'ROUTE_TITLE.directory',
        //   skipPageView: true
        // },
        '/org/:currentOrgId/directory/:path*': {
          appPermission: 'platformPrograms',
          context: 'app.iframeContent.directory',
          iframeContent: 'directory',
          title: 'ROUTE_TITLE.directory',
          skipPageView: true
        },
        '/org/:currentOrgId/billing': {
          redirectTo: '/org/:currentOrgId/payments',
          reloadOnSearch: false
        },
        '/org/:currentOrgId/sale_items': {
          appPermission: 'saleItems',
          iframeContent: 'storeUI',
          context: 'app.iframeContent.storeUI',
          title: 'ROUTE_TITLE.store_items',
          subApplication: 'items',
          skipPageView: true
        },
        '/org/:currentOrgId/sale_items/:path*': {
          appPermission: 'saleItems',
          iframeContent: 'storeUI',
          context: 'app.iframeContent.storeUI',
          title: 'ROUTE_TITLE.store_items',
          subApplication: 'items',
          skipPageView: true
        },
        // this route should be deprecated when we do new discounts clean up
        '/org/:currentOrgId/discounts': {
          appPermission: 'discounts',
          context: 'app.billing.discounts',
          title: 'ROUTE_TITLE.discounts',
          pageDepths: { depth1: 'HQ', depth2: 'Discounts' }
        },
        // this route should be deprecated when we do new discounts clean up
        '/org/:currentOrgId/discounts/:discountId/detail': {
          appPermission: 'discounts',
          context: 'app.billing.discounts.detail',
          title: 'ROUTE_TITLE.discount_detail',
          pageDepths: { depth1: 'HQ', depth2: 'Discounts', depth3: 'DiscountDetail' }
        },
        '/org/:currentOrgId/promotions': {
          appPermission: 'discounts',
          context: 'app.iframeContent.discounts',
          iframeContent: 'storeUI',
          subApplication: 'discounts',
          title: 'ROUTE_TITLE.discounts',
          leftNavKey: 'discounts',
          skipPageView: true
        },
        '/org/:currentOrgId/promotions/:path*': {
          appPermission: 'discounts',
          context: 'app.iframeContent.discounts',
          iframeContent: 'storeUI',
          subApplication: 'discounts',
          title: 'ROUTE_TITLE.discounts',
          leftNavKey: 'discounts',
          skipPageView: true
        },
        '/org/:currentOrgId/eligibility/:eligibilityRuleSetId/persona/:personaId': {
          redirectTo: '/org/:currentOrgId/eligibility/:eligibilityRuleSetId/active/persona/:personaId'
        },
        '/org/:currentOrgId/eligibility/:eligibilityRuleSetId': {
          redirectTo: '/org/:currentOrgId/eligibility/:eligibilityRuleSetId/active'
        },
        '/org/:currentOrgId/eligibility/:eligibilityRuleSetId/:status': {
          appPermission: 'eligibility',
          context: 'app.billing.eligibility',
          title: 'ROUTE_TITLE.eligibility',
          reloadOnSearch: false,
          leftNavKey: 'memberships',
          pageDepths: { depth1: 'HQ', depth2: 'Memberships', depth3: 'MembershipEligiblity', depth4: '' }
        },
        '/org/:currentOrgId/eligibility/:eligibilityRuleSetId/:status/persona/:personaId': {
          appPermission: 'eligibility',
          context: 'app.billing.eligibility.detail',
          title: 'ROUTE_TITLE.eligibility',
          reloadOnSearch: false,
          leftNavKey: 'memberships',
          pageDepths: { depth1: 'HQ', depth2: 'Memberships', depth3: 'MembershipEligiblity', depth4: '', depth5: 'PersonaDetails' }
        },
        '/org/:currentOrgId/reports/activity': {
          appPermission: 'reports',
          context: 'app.billing.reports.activity',
          title: 'ROUTE_TITLE.activity_report',
          pageDepths: { depth1: 'HQ', depth2: 'Reports', depth3: 'Activity' }
        },
        '/org/:currentOrgId/reports/payouts': {
          appPermission: 'reports',
          context: 'app.billing.reports.payouts',
          title: 'ROUTE_TITLE.payouts_report',
          pageDepths: { depth1: 'HQ', depth2: 'Reports', depth3: 'Payouts' }
        },
        '/org/:currentOrgId/reports/payouts/:payoutId/detail': {
          appPermission: 'reports',
          context: 'app.billing.reports.payouts.detail',
          title: 'ROUTE_TITLE.payout_detail',
          pageDepths: { depth1: 'HQ', depth2: 'Reports', depth3: 'Payouts', depth4: 'PayoutDetail' }
        },
        '/org/:currentOrgId/transactions': {
          appPermission: 'transactions',
          context: 'app.billing.transactions',
          title: 'ROUTE_TITLE.transactions_list',
          pageDepths: { depth1: 'HQ', depth2: 'Transactions' }
        },
        '/org/:currentOrgId/transactions/:transactionId/detail': {
          appPermission: 'transactions',
          context: 'app.billing.transactions.detail',
          title: 'ROUTE_TITLE.transactions_detail',
          pageDepths: { depth1: 'HQ', depth2: 'Transactions', depth3: 'TransactionDetails' }
        },
        '/org/:currentOrgId/payment_terms': {
          appPermission: 'paymentTerms',
          context: 'app.billing.payment_terms',
          title: 'ROUTE_TITLE.payment_terms',
          pageDepths: { depth1: 'HQ', depth2: 'PaymentTerms' }
        },
        '/org/:currentOrgId/payment_terms/:paymentTermId/detail': {
          appPermission: 'paymentTerms',
          context: 'app.billing.payment_terms.detail',
          title: 'ROUTE_TITLE.payment_terms_detail',
          pageDepths: { depth1: 'HQ', depth2: 'PaymentTerms', depth3: 'Details' }
        },
        '/org/:currentOrgId/people': {
          appPermission: 'profiles',
          context: 'app.billing.personas',
          title: 'ROUTE_TITLE.people_list',
          leftNavKey: 'profiles',
          pageDepths: { depth1: 'HQ', depth2: 'Directory', depth3: 'People' }
        },
        '/org/:currentOrgId/people/customize/columns': {
          appPermission: 'profiles',
          context: 'app.billing.personas.editColumns',
          title: 'ROUTE_TITLE.customize_people_list',
          pageDepths: { depth1: 'HQ', depth2: 'Directory', depth3: 'People', depth4: 'EditColumns' }
        },
        '/org/:currentOrgId/people/:personaId': {
          appPermission: 'profiles',
          redirectTo: '/org/:currentOrgId/people/:personaId/activity'
        },
        '/org/:currentOrgId/people/:personaId/activity': {
          appPermission: 'profiles',
          context: 'app.billing.personas.persona.invoices',
          title: 'ROUTE_TITLE.persona_invoice_activity',
          leftNavKey: 'profiles',
          pageDepths: { depth1: 'HQ', depth2: 'Directory', depth3: 'People', depth4: 'Invoices' }
        },
        '/org/:currentOrgId/people/:personaId/activity/:invoiceId': {
          appPermission: 'profiles',
          context: 'app.billing.personas.persona.invoices.invoice',
          title: 'ROUTE_TITLE.persona_invoice_detail',
          leftNavKey: 'profiles',
          pageDepths: { depth1: 'HQ', depth2: 'Directory', depth3: 'People', depth4: 'Invoices', depth5: 'InvoiceActivity' }
        },
        '/org/:currentOrgId/people/:personaId/memberships': {
          appPermission: 'profiles',
          context: 'app.billing.personas.persona.info.memberships',
          title: 'ROUTE_TITLE.persona_memberships',
          reloadOnSearch: true,
          leftNavKey: 'profiles',
          pageDepths: { depth1: 'HQ', depth2: 'Directory', depth3: 'People', depth4: 'Memberships' }
        },
        '/org/:currentOrgId/people/:personaId/memberships/:eligibilityRuleSetId': {
          appPermission: 'affiliateEligibility',
          context: 'app.billing.personas.persona.info.memberships.detail',
          title: 'ROUTE_TITLE.persona_memberships',
          reloadOnSearch: true,
          leftNavKey: 'profiles',
          pageDepths: { depth1: 'HQ', depth2: 'Directory', depth3: 'People', depth4: 'Memberships', depth5: 'EligibilityDetail' }
        },
        '/org/:currentOrgId/people/:personaId/credentials': {
          appPermission: 'profiles',
          context: 'app.billing.personas.persona.info.credentials',
          title: 'ROUTE_TITLE.persona_credentials',
          reloadOnSearch: true,
          leftNavKey: 'profiles',
          pageDepths: { depth1: 'HQ', depth2: 'Directory', depth3: 'People', depth4: 'Credentials' }
        },
        '/org/:currentOrgId/people/:personaId/profile': {
          appPermission: 'profiles',
          context: 'app.billing.personas.persona.info.profile',
          title: 'ROUTE_TITLE.persona_profile',
          reloadOnSearch: true,
          leftNavKey: 'profiles',
          pageDepths: { depth1: 'HQ', depth2: 'Directory', depth3: 'People', depth4: 'Profile' }
        },
        '/org/:currentOrgId/people/:personaId/roles': {
          appPermission: 'contactRoles',
          context: 'app.billing.personas.persona.info.roles',
          title: 'ROUTE_TITLE.persona_roles',
          reloadOnSearch: true,
          leftNavKey: 'profiles',
          pageDepths: { depth1: 'HQ', depth2: 'Directory', depth3: 'People', depth4: 'Roles' }
        },
        '/org/:currentOrgId/people/:personaId/permissions': {
          appPermission: 'adminManagement',
          context: 'app.billing.personas.persona.info.permissions',
          title: 'ROUTE_TITLE.persona_permissions',
          reloadOnSearch: true,
          leftNavKey: 'profiles',
          pageDepths: { depth1: 'HQ', depth2: 'Directory', depth3: 'People', depth4: 'Permissions' }
        },
        '/org/:currentOrgId/people/:personaId/roles/website-permissions': {
          appPermission: 'contactRoles',
          context: 'app.billing.personas.persona.info.roles.website_permissions',
          title: 'ROUTE_TITLE.persona_roles',
          reloadOnSearch: true,
          leftNavKey: 'profiles',
          pageDepths: { depth1: 'HQ', depth2: 'Directory', depth3: 'People', depth4: 'Website Permissions' }
        },
        '/org/:currentOrgId/people/:personaId/roles/hq-tool-permissions': {
          appPermission: 'contactRoles',
          context: 'app.billing.personas.persona.info.roles.hq_tool_permissions',
          title: 'ROUTE_TITLE.persona_roles',
          reloadOnSearch: true,
          leftNavKey: 'profiles',
          pageDepths: { depth1: 'HQ', depth2: 'Directory', depth3: 'People', depth4: 'HQ Tool Permissions' }
        },
        // This is just here for existing bookmarks
        '/org/:currentOrgId/people/:personaId/registration_history/org/:organizationId': {
          redirectTo: '/org/:currentOrgId/people/:personaId/registration_history'
        },
        '/org/:currentOrgId/people/:personaId/registration_history': {
          appPermission: 'profiles',
          context: 'app.billing.personas.persona.info.registration_history.org',
          title: 'ROUTE_TITLE.persona_org_info',
          reloadOnSearch: true,
          leftNavKey: 'profiles',
          pageDepths: { depth1: 'HQ', depth2: 'Directory', depth3: 'People', depth4: 'RegistrationHistory' }
        },
        '/org/:currentOrgId/people/:personaId/registration_history/survey_result/:surveyResultId': {
          appPermission: 'profiles',
          context: 'app.billing.personas.persona.info.registration_history.org.surveyResult',
          title: 'ROUTE_TITLE.persona_org_info', // view mode (expanded survey)
          reloadOnSearch: true,
          leftNavKey: 'profiles',
          pageDepths: { depth1: 'HQ', depth2: 'Directory', depth3: 'People', depth4: 'RegistrationHistory', depth5: 'ViewSurvey' }
        },
        '/org/:currentOrgId/people/:personaId/registration_history/survey_result/:surveyResultId/:formId': {
          appPermission: 'profiles',
          context: 'app.billing.personas.persona.info.registration_history.org.surveyResult.form',
          title: 'ROUTE_TITLE.persona_org_info', // edit mode
          reloadOnSearch: true,
          leftNavKey: 'profiles',
          pageDepths: { depth1: 'HQ', depth2: 'Directory', depth3: 'People', depth4: 'RegistrationHistory', depth5: 'EditSurvey' }
        },
        '/org/:currentOrgId/people/:personaId/roster_history': {
          appPermission: 'rosterHistory',
          context: 'app.billing.personas.persona.info.roster_history',
          title: 'ROUTE_TITLE.persona_roster_history',
          reloadOnSearch: true,
          leftNavKey: 'profiles',
          pageDepths: { depth1: 'HQ', depth2: 'Directory', depth3: 'People', depth4: 'RosterHistory' }
        },
        '/org/:currentOrgId/people/:personaId/dibs': {
          appPermission: 'dibs',
          context: 'app.billing.personas.persona.info.dibs',
          title: 'ROUTE_TITLE.persona_dibs',
          reloadOnSearch: true,
          leftNavKey: 'profiles',
          pageDepths: { depth1: 'HQ', depth2: 'Directory', depth3: 'People', depth4: 'Dibs' }
        },
        '/org/:currentOrgId/billing/groups': {
          redirectTo: '/org/:currentOrgId/groups'
        },
        '/org/:currentOrgId/groups': {
          appPermission: 'profiles',
          context: 'app.billing.groups',
          title: 'ROUTE_TITLE.group_list',
          leftNavKey: 'profiles',
          pageDepths: { depth1: 'HQ', depth2: 'Directory', depth3: 'GroupsAndRosters' }
        },
        '/org/:currentOrgId/groups/:groupId': {
          appPermission: 'profiles',
          context: 'app.billing.groups.detail.info',
          title: 'ROUTE_TITLE.group_detail',
          leftNavKey: 'profiles',
          pageDepths: { depth1: 'HQ', depth2: 'Directory', depth3: 'GroupsAndRosters', depth4: 'GroupDetail' }
        },
        '/org/:currentOrgId/groups/:groupId/members': {
          appPermission: 'profiles',
          context: 'app.billing.groups.detail.members',
          title: 'ROUTE_TITLE.group_detail',
          leftNavKey: 'profiles',
          pageDepths: { depth1: 'HQ', depth2: 'Directory', depth3: 'GroupsAndRosters', depth4: 'GroupMembers' }
        },
        '/org/:currentOrgId/groups/:groupId/members/customize': {
          appPermission: 'profiles',
          context: 'app.billing.groups.detail.members.editColumns',
          title: 'ROUTE_TITLE.group_detail',
          pageDepths: { depth1: 'HQ', depth2: 'Directory', depth3: 'GroupsAndRosters', depth4: 'EditGroupColumns' }
        },
        '/org/:currentOrgId/payments': {
          redirectTo: '/org/:currentOrgId/billing/payments/bills'
        },
        '/org/:currentOrgId/billing/payments': {
          redirectTo: '/org/:currentOrgId/billing/payments/bills'
        },
        '/org/:currentOrgId/billing/payments/sent': {
          appPermission: 'powerPay',
          context: 'app.billing.invoiceGroups.sent',
          title: 'ROUTE_TITLE.invoice_group_sent',
          leftNavKey: 'power_pay',
          pageDepths: { depth1: 'HQ', depth2: 'Invoices', depth3: 'SentRequests' }
        },
        '/org/:currentOrgId/billing/payments/sent/:invoiceGroupId': {
          redirectTo: '/org/:currentOrgId/billing/payments/sent/:invoiceGroupId/activity'
        },
        '/org/:currentOrgId/billing/payments/sent/:invoiceGroupId/activity': {
          appPermission: 'powerPay',
          context: 'app.billing.invoiceGroups.sent.invoices',
          title: 'ROUTE_TITLE.invoice_group_detail',
          leftNavKey: 'power_pay',
          pageDepths: { depth1: 'HQ', depth2: 'Invoices', depth3: 'SentRequests', depth4: 'PaymentRequest' }
        },
        '/org/:currentOrgId/billing/payments/sent/:invoiceGroupId/activity/:invoiceId': {
          appPermission: 'powerPay',
          context: 'app.billing.invoiceGroups.sent.invoices.invoice',
          title: 'ROUTE_TITLE.invoice_detail',
          leftNavKey: 'power_pay',
          pageDepths: { depth1: 'HQ', depth2: 'Invoices', depth3: 'SentRequests', depth4: 'PaymentRequest', depth5: 'Payment' }
        },
        '/org/:currentOrgId/billing/payments/bills': {
          appPermission: 'powerPay',
          context: 'app.billing.invoiceGroups.bills',
          title: 'ROUTE_TITLE.invoice_list',
          pageDepths: { depth1: 'HQ', depth2: 'Invoices' }
        },
        '/org/:currentOrgId/billing/payments/bills/:invoiceId/detail': {
          appPermission: 'powerPay',
          context: 'app.billing.invoiceGroups.bills.detail',
          title: 'ROUTE_TITLE.invoice_detail',
          pageDepths: { depth1: 'HQ', depth2: 'Invoices', depth3: 'SaleDetail' }
        },
        // This has special logic to add some query params to open an edim wizard
        '/org/:currentOrgId/financial_settings/edit': {
          appPermission: 'financialSettingsUi',
          context: 'app.iframeContent.editFinancialSettings',
          iframeContent: 'editFinancialSettings',
          title: 'ROUTE_TITLE.financial_settings',
          leftNavKey: 'org_financial_settings',
          pageDepths: { depth1: 'HQ', depth2: 'editFinancialSettings' }
        },
        '/org/:currentOrgId/financial_settings': {
          appPermission: 'financialSettingsUi',
          context: 'app.iframeContent.financialSettings',
          iframeContent: 'financialSettings',
          title: 'ROUTE_TITLE.financial_settings',
          skipPageView: true
        },
        '/org/:currentOrgId/financial_settings/:path*': {
          appPermission: 'financialSettingsUi',
          context: 'app.iframeContent.financialSettings',
          iframeContent: 'financialSettings',
          title: 'ROUTE_TITLE.financial_settings',
          skipPageView: true
        },
        '/org/:currentOrgId/account_settings': {
          context: 'app.accountSettings',
          title: 'ROUTE_TITLE.account_settings',
          pageDepths: { depth1: 'HQ', depth2: 'AccountSettings' }
        },
        // TODO: The next 3 items are deprecated and can be removed once all
        // organizations are fully migrated to stripe.
        '/org/:currentOrgId/billing/financial_settings/': {
          redirectTo: '/org/:currentOrgId/billing/financial_settings/bank_account'
        },
        '/org/:currentOrgId/billing/financial_settings/processor_settings': {
          context: 'app.billing.financialSettings.processorSettings',
          title: 'ROUTE_TITLE.processor_settings',
          pageDepths: { depth1: 'HQ', depth2: 'OrganizationSettings', depth3: 'ProcessorSettings' }
        },
        '/org/:currentOrgId/billing/financial_settings/bank_account': {
          context: 'app.billing.financialSettings.bankAccountSettings',
          title: 'ROUTE_TITLE.bank_account_settings',
          pageDepths: { depth1: 'HQ', depth2: 'OrganizationSettings', depth3: 'BankAccounts' }
        },
        // NOTE: end deprecated financial routes
        '/org/:currentOrgId/financial_provisioning_restriction': {
          context: 'app.iframeContent.financialProvisioningRestriction',
          iframeContent: 'financialProvisioningRestriction',
          title: 'ROUTE_TITLE.financial_settings',
          pageDepths: { depth1: 'HQ', depth2: 'FinancialProvisioningRestriction' }
        },
        '/org/:currentOrgId/platform_programs': {
          appPermission: 'platformPrograms',
          context: 'app.iframeContent.programs',
          iframeContent: 'programs',
          title: 'ROUTE_TITLE.platform_programs',
          skipPageView: true
        },
        '/org/:currentOrgId/platform_programs/:path*': {
          appPermission: 'platformPrograms',
          context: 'app.iframeContent.programs',
          iframeContent: 'programs',
          title: 'ROUTE_TITLE.platform_programs',
          skipPageView: true
        },
        '/org/:currentOrgId/financial_reports': {
          appPermission: 'financialSettingsUi',
          context: 'app.iframeContent.financialReports',
          iframeContent: 'financialReports',
          title: 'ROUTE_TITLE.financial_reports',
          leftNavKey: 'reports',
          skipPageView: true
        },
        '/org/:currentOrgId/financial_reports/:path*': {
          appPermission: 'financialSettingsUi',
          context: 'app.iframeContent.financialReports',
          iframeContent: 'financialReports',
          title: 'ROUTE_TITLE.financial_reports',
          leftNavKey: 'reports',
          skipPageView: true
        },
        '/org/:currentOrgId/squad_locker': {
          appPermission: 'apparelStore',
          context: 'app.iframeContent.squadLocker',
          iframeContent: 'squadLocker',
          title: 'ROUTE_TITLE.squad_locker',
          pageDepths: { depth1: 'HQ', depth2: 'CustomApparel' }
        },
        '/org/:currentOrgId/custom-apparel': {
          appPermission: 'customApparelV2',
          context: 'app.iframeContent.customApparel',
          iframeContent: 'customApparel',
          title: 'ROUTE_TITLE.custom_apparel'
        },
        '/org/:currentOrgId/rostering': {
          redirectTo: '/org/:currentOrgId/rostering/assign/players'
        },
        '/org/:currentOrgId/rostering/assign/players': {
          appPermission: 'rosterManagement',
          context: 'app.billing.rostering.assign.players',
          title: 'ROUTE_TITLE.rostering_assign_players',
          pageDepths: { depth1: 'HQ', depth2: 'Rostering', depth3: 'AssignPlayers' }
        },
        '/org/:currentOrgId/rostering/assign/staff': {
          appPermission: 'rosterManagement',
          context: 'app.billing.rostering.assign.staff',
          title: 'ROUTE_TITLE.rostering_assign_staff',
          pageDepths: { depth1: 'HQ', depth2: 'Rostering', depth3: 'AssignStaff' }
        },
        '/org/:currentOrgId/rostering/transfer': {
          appPermission: 'rosterManagement',
          context: 'app.billing.rostering.transfer',
          title: 'ROUTE_TITLE.rostering_transfer',
          pageDepths: { depth1: 'HQ', depth2: 'Rostering', depth3: 'TransferPeople' }
        },
        '/org/:currentOrgId/sponsorships': {
          context: 'app.iframeContent.sponsorships',
          iframeContent: 'sponsorships',
          title: 'ROUTE_TITLE.sponsorships',
          pageDepths: { depth1: 'HQ', depth2: 'Sponsorships' }
        },
        '/org/:currentOrgId/template-select': {
          context: 'app.provisioning.templateSelect',
          title: 'ROUTE_TITLE.template_select'
        },
        '/org/:currentOrgId/design-center': {
          context: 'app.iframeContent.designCenter',
          iframeContent: 'designCenter',
          title: 'ROUTE_TITLE.design_center',
          pageDepths: { depth1: 'HQ', depth2: 'DesignCenter' }
        },
        '/org/:currentOrgId/home': {
          redirectTo: '/org/:currentOrgId'
        },
        '/org/:currentOrgId/perks': {
          context: 'app.billing.perks',
          title: 'ROUTE_TITLE.perks',
          pageDepths: { depth1: 'HQ', depth2: 'Perks' }
        },
        '/org/:currentOrgId/marketplace': {
          appPermission: 'marketplace',
          context: 'app.iframeContent.marketplace',
          iframeContent: 'marketplace',
          title: 'ROUTE_TITLE.marketplace',
          skipPageView: true
        },
        '/org/:currentOrgId/api_integrations': {
          context: 'app.iframeContent.apiIntegrations',
          iframeContent: 'apiIntegrations',
          title: 'ROUTE_TITLE.api_integrations',
          skipPageView: true
        },
        '/org/:currentOrgId/api_integrations/:path*': {
          context: 'app.iframeContent.apiIntegrations',
          iframeContent: 'apiIntegrations',
          title: 'ROUTE_TITLE.api_integrations',
          skipPageView: true
        },
        '/org/:currentOrgId/commerce': {
          appPermission: 'commerce',
          context: 'app.iframeContent.commerce',
          iframeContent: 'commerce',
          title: 'ROUTE_TITLE.commerce',
          skipPageView: true
        },
        '/org/:currentOrgId/commerce/:path*': {
          appPermission: 'commerce',
          context: 'app.iframeContent.commerce',
          iframeContent: 'commerce',
          title: 'ROUTE_TITLE.commerce',
          skipPageView: true
        },

        // TODO: Remove these once links are pointing to program_listings

        '/org/:currentOrgId/programs': {
          redirectTo: '/org/:currentOrgId/program-listings/active'
        },
        '/org/:currentOrgId/programs/active': {
          redirectTo: '/org/:currentOrgId/program-listings/active'
        },
        '/org/:currentOrgId/programs/add': {
          redirectTo: '/org/:currentOrgId/program-listings/add'
        },
        '/org/:currentOrgId/programs/drafts': {
          redirectTo: '/org/:currentOrgId/program-listings/drafts'
        },
        '/org/:currentOrgId/programs/archive': {
          redirectTo: '/org/:currentOrgId/program-listings/archive'
        },
        '/org/:currentOrgId/programs/new': {
          redirectTo: '/org/:currentOrgId/program-listings/new'
        },
        '/org/:currentOrgId/program-preview/:programListingId': {
          redirectTo: '/org/:currentOrgId/program-preview/:programListingId'
        },
        '/org/:currentOrgId/programs/:programListingId': {
          redirectTo: '/org/:currentOrgId/program-listings/:programListingId'
        },
        '/org/:currentOrgId/programs/:programListingId/preview': {
          redirectTo: '/org/:currentOrgId/program-listings/:programListingId/preview'
        },
        '/org/:currentOrgId/programs/:programListingId/success': {
          redirectTo: '/org/:currentOrgId/program-listings/:programListingId/success'
        },


        '/org/:currentOrgId/program-listings': {
          redirectTo: '/org/:currentOrgId/program-listings/active'
        },
        '/org/:currentOrgId/program-listings/active': {
          appPermission: 'programListings',
          context: 'app.billing.programListings.active',
          title: 'ROUTE_TITLE.program_listings_active',
          pageDepths: { depth1: 'HQ', depth2: 'ProgramListings', depth3: '' }
        },
        '/org/:currentOrgId/program-listings/add': {
          appPermission: 'programListings',
          context: 'app.billing.programListings.programListing.add',
          title: 'ROUTE_TITLE.program_listings_add',
          pageDepths: { depth1: 'HQ', depth2: 'ProgramListings', depth3: 'AddProgramListing' }
        },
        '/org/:currentOrgId/program-listings/drafts': {
          appPermission: 'programListings',
          context: 'app.billing.programListings.drafts',
          title: 'ROUTE_TITLE.program_listings_drafts',
          pageDepths: { depth1: 'HQ', depth2: 'ProgramListings', depth3: '' }
        },
        '/org/:currentOrgId/program-listings/archive': {
          appPermission: 'programListings',
          context: 'app.billing.programListings.archive',
          title: 'ROUTE_TITLE.program_listings_archive',
          pageDepths: { depth1: 'HQ', depth2: 'ProgramListings', depth3: '' }
        },
        '/org/:currentOrgId/program-listings/new': {
          appPermission: 'programListings',
          context: 'blank.newProgram',
          title: 'ROUTE_TITLE.program_listings_add',
          pageDepths: { depth1: 'HQ', depth2: 'NewProgramListing' }
        },
        '/org/:currentOrgId/program-listing-preview/:programListingId': {
          appPermission: 'programListings',
          context: 'blank.programPreview',
          title: 'ROUTE_TITLE.program_listing_preview',
          pageDepths: { depth1: 'HQ', depth2: 'ProgramListingPreview', depth3: 'Detail' }
        },
        '/org/:currentOrgId/program-listings/:programListingId': {
          appPermission: 'programListings',
          context: 'app.billing.programListings.programListing.info',
          title: 'ROUTE_TITLE.program_listings',
          pageDepths: { depth1: 'HQ', depth2: 'ProgramListings', depth3: 'Detail' }
        },
        '/org/:currentOrgId/program-listings/:programListingId/preview': {
          appPermission: 'programListings',
          context: 'app.billing.programListings.programListing.preview',
          title: 'ROUTE_TITLE.program_listings',
          pageDepths: { depth1: 'HQ', depth2: 'ProgramListings', depth3: 'Detail', depth4: 'Preview' }
        },
        '/org/:currentOrgId/program-listings/:programListingId/success': {
          appPermission: 'programListings',
          context: 'app.billing.programListings.programListing.success',
          title: 'ROUTE_TITLE.program_listings',
          pageDepths: { depth1: 'HQ', depth2: 'ProgramListings', depth3: 'Detail', depth4: 'Success' }
        },
        '/org/:currentOrgId/external_tourney': {
          context: 'app.billing.externalTourney'
        },
        '/org/:currentOrgId/claim': {
          appPermission: 'orgClaim',
          context: 'blank.claim',
          title: 'ROUTE_TITLE.org_claim',
          pageDepths: { depth1: 'HQ', depth2: 'OrgClaim' }
        },
        '/org/:currentOrgId/claim-preview': {
          appPermission: 'orgClaim',
          context: 'blank.claimPreview',
          title: 'ROUTE_TITLE.org_claim_preview',
          pageDepths: { depth1: 'HQ', depth2: 'ClaimPreview' }
        },
        '/org/:currentOrgId/membership-requests': {
          appPermission: 'memberships',
          context: 'app.billing.membershipRequests',
          title: 'ROUTE_TITLE.membership_request',
          pageDepths: { depth1: 'HQ', depth2: 'Memberships', depth3: 'PublishedMemberships', depth4: 'MembershipRequests' }
        },
        '/org/:currentOrgId/memberships/published': {
          appPermission: 'memberships',
          context: 'app.billing.memberships.published',
          title: 'ROUTE_TITLE.memberships_published',
          pageDepths: { depth1: 'HQ', depth2: 'Memberships', depth3: 'PublishedMemberships' }
        },
        '/org/:currentOrgId/memberships/unpublished': {
          appPermission: 'memberships',
          context: 'app.billing.memberships.unpublished',
          title: 'ROUTE_TITLE.memberships_unpublished',
          leftNavKey: 'memberships',
          pageDepths: { depth1: 'HQ', depth2: 'Memberships', depth3: '' }
        },
        '/org/:currentOrgId/memberships/archived': {
          appPermission: 'memberships',
          context: 'app.billing.memberships.archived',
          title: 'ROUTE_TITLE.memberships_archived',
          leftNavKey: 'memberships',
          pageDepths: { depth1: 'HQ', depth2: 'Memberships', depth3: '' }
        },
        '/org/:currentOrgId/memberships': {
          redirectTo: '/org/:currentOrgId/memberships/published'
        },
        '/org/:currentOrgId/memberships/current': {
          redirectTo: '/org/:currentOrgId/memberships/published'
        },
        '/org/:currentOrgId/people/buy-memberships/purchased': {
          appPermission: 'profiles',
          context: 'app.billing.personas.membershipsPurchased',
          title: 'ROUTE_TITLE.memberships_confirmation',
          pageDepths: { depth1: 'HQ', depth2: 'People', depth3: 'BuyMemberships', depth4: 'Purchased' }
        },
        '/org/:currentOrgId/memberships/:membershipDefinitionId/info': {
          appPermission: 'memberships',
          context: 'app.billing.memberships.membershipDefinition.info',
          title: 'ROUTE_TITLE.memberships_detail',
          leftNavKey: 'memberships',
          pageDepths: { depth1: 'HQ', depth2: 'Memberships', depth3: '', depth4: 'MembershipInfo' }
        },
        '/org/:currentOrgId/memberships/club-assignments': {
          appPermission: 'memberships',
          context: 'app.billing.memberships.clubAssignments',
          title: 'ROUTE_TITLE.club_assignments',
          pageDepths: { depth1: 'HQ', depth2: 'Memberships', depth3: 'ClubAssignments' }
        },
        '/org/:currentOrgId/memberships/:membershipDefinitionId/manage-invites': {
          appPermission: 'memberships',
          context: 'app.billing.memberships.manageInvites',
          title: 'ROUTE_TITLE.manage_invites',
          pageDepths: { depth1: 'HQ', depth2: 'Memberships', depth3: '', depth4: 'ManageInvites' }
        },
        '/org/:currentOrgId/governing_seasons': {
          redirectTo: '/org/:currentOrgId/governing_seasons/active'
        },
        '/org/:currentOrgId/governing_seasons/active': {
          appPermission: 'governingSeasons',
          context: 'app.billing.governingSeasons.active',
          title: 'ROUTE_TITLE.GOVERNING_SEASONS.active',
          pageDepths: { depth1: 'HQ', depth2: 'GoverningSeasons', depth3: '', depth4: 'List' }
        },
        '/org/:currentOrgId/governing_seasons/active/:seasonId': {
          appPermission: 'governingSeasonParent',
          context: 'app.billing.governingSeasons.active.detail',
          title: 'ROUTE_TITLE.GOVERNING_SEASONS.active',
          skipPageView: true
        },
        '/org/:currentOrgId/season_management': {
          appPermission: 'seasonManagement',
          context: 'app.seasonManagement.seasons',
          iframeContent: 'seasonManagement',
          title: 'ROUTE_TITLE.season_management',
          skipPageView: true
        },
        '/org/:currentOrgId/season_management/past-seasons-redirect': {
          context: 'app.seasonManagement.seasons.past',
          redirectTo: '/org/:currentOrgId/season_management/seasons/past'
        },
        '/org/:currentOrgId/season_management/:path*': {
          appPermission: 'seasonManagement',
          context: 'app.seasonManagement.seasons',
          iframeContent: 'seasonManagement',
          title: 'ROUTE_TITLE.season_management',
          skipPageView: true
        },
        '/org/:currentOrgId/location_management': {
          appPermission: 'seasonManagement',
          context: 'app.seasonManagement.locations',
          title: 'ROUTE_TITLE.LEAGUE_LOCATIONS.active',
          pageDepths: { depth1: 'HQ', depth2: 'SeasonManagement', depth3: 'Venues' }
        },
        '/org/:currentOrgId/league_seasons': {
          redirectTo: '/org/:currentOrgId/league_seasons/active'
        },
        '/org/:currentOrgId/league_seasons/active': {
          appPermission: 'leagueSeasons',
          context: 'app.billing.leagueSeasons.seasons.active',
          title: 'ROUTE_TITLE.LEAGUE_SEASONS.active',
          pageDepths: { depth1: 'HQ', depth2: 'SeasonManagement', depth3: 'List' }
        },
        '/org/:currentOrgId/league_seasons/active/:seasonId': {
          appPermission: 'leagueSeasonParent',
          context: 'app.billing.leagueSeasons.seasons.active.detail',
          title: 'ROUTE_TITLE.LEAGUE_SEASONS.active',
          skipPageView: true
        },
        '/org/:currentOrgId/league_locations': {
          redirectTo: '/org/:currentOrgId/league_locations/active'
        },
        '/org/:currentOrgId/league_locations/active': {
          appPermission: 'leagueSeasons',
          context: 'app.billing.leagueSeasons.locations.active',
          title: 'ROUTE_TITLE.LEAGUE_LOCATIONS.active',
          pageDepths: { depth1: 'HQ', depth2: 'SeasonManagement', depth3: 'Venues' }
        },
        '/org/:currentOrgId/waivers': {
          redirectTo: '/org/:currentOrgId/electronic-documents'
        },
        '/org/:currentOrgId/waivers/active': {
          redirectTo: '/org/:currentOrgId/electronic-documents'
        },
        '/org/:currentOrgId/electronic-documents': {
          appPermission: 'electronicDocuments',
          context: 'app.electronicDocuments',
          title: 'ROUTE_TITLE.electronic_documents',
          skipPageView: true
        },
        '/org/:currentOrgId/electronic-documents/:docId': {
          redirectTo: '/org/:currentOrgId/electronic-documents/:docId/info',
        },
        '/org/:currentOrgId/electronic-documents/:docId/info': {
          appPermission: 'electronicDocuments',
          context: 'app.electronicDocuments.info',
          title: 'ROUTE_TITLE.electronic_documents',
        },
        '/org/:currentOrgId/hq/product-no-access': {
          context: 'app.billing.hq.productNoAccess',
          title: 'ROUTE_TITLE.product_no_access',
          pageDepths: { depth1: 'HQ', depth2: 'NoAccess' }
        },
        '/org/:currentOrgId/competition_programs': {
          redirectTo: '/org/:currentOrgId/competition_programs/active'
        },
        '/org/:currentOrgId/competition_programs/active': {
          appPermission: 'competitionPrograms',
          context: 'app.billing.competitionPrograms.active',
          title: 'ROUTE_TITLE.COMPETITION_PROGRAMS.active',
          pageDepths: { depth1: 'HQ', depth2: 'CompetitionPrograms', depth3: 'List' }
        },
        '/org/:currentOrgId/competition_programs/active/:seasonId': {
          appPermission: 'competitionProgramParent',
          context: 'app.billing.competitionPrograms.active.detail',
          title: 'ROUTE_TITLE.COMPETITION_PROGRAMS.active',
          skipPageView: true
        },
        '/org/:currentOrgId/proof_of_birth': {
          appPermission: 'proofOfBirth',
          context: 'app.billing.proofOfBirth',
          title: 'ROUTE_TITLE.proof_of_birth',
          pageDepths: { depth1: 'HQ', depth2: 'ProofOfBirth' }
        },
        '/org/:currentOrgId/settings': {
          appPermission: 'orgSettings',
          context: 'app.settings.organization.general',
          title: 'ORG_SETTINGS.ROUTE_TITLE.general',
          pageDepths: { depth1: 'HQ', depth2: 'OrganizationSettings', depth3: 'General' }
        },
        '/org/:currentOrgId/settings/listing': {
          appPermission: 'orgSettings',
          context: 'app.settings.organization.listing',
          title: 'ORG_SETTINGS.ROUTE_TITLE.listing',
          pageDepths: { depth1: 'HQ', depth2: 'OrganizationSettings', depth3: 'Listing' }
        },
        // TODO: maybe remove redirects at some point
        '/org/:currentOrgId/settings/rostering_settings': {
          redirectTo: '/org/:currentOrgId/settings',
          context: 'app.rosteringSettings'
        },
        '/org/:currentOrgId/old-settings': {
          redirectTo: '/org/:currentOrgId/settings',
          context: 'app.billing.settings'
        },
        '/org/:currentOrgId/eligibility_search': {
          redirectTo: '/org/:currentOrgId/eligibility_search/members',
          context: 'app.eligibilitySearch'
        },
        '/org/:currentOrgId/eligibility_search/:path': {
          appPermission: 'eligibilitySearch',
          context: 'app.eligibilitySearch',
          iframeContent: 'eligibilitySearch',
          title: 'ROUTE_TITLE.eligibility_search',
          skipPageView: true
        },
        '/org/:currentOrgId/eligibility_search/:path/detail': {
          appPermission: 'eligibilitySearch',
          context: 'app.eligibilitySearch.detail',
          iframeContent: 'eligibilitySearch',
          title: 'ROUTE_TITLE.eligibility_search',
          skipPageView: true
        },
        '/org/:currentOrgId/credential_search': {
          redirectTo: '/org/:currentOrgId/credentials/credential_search',
          context: 'app.credentialSearch'
        },
        '/org/:currentOrgId/credentials/:path*': {
          appPermission: 'eligibilitySearch',
          context: 'app.credentialSearch',
          iframeContent: 'credentialSearch',
          title: 'ROUTE_TITLE.credential_search',
          skipPageView: true
        },
        '/org/:currentOrgId/review_duplicates': {
          appPermission: 'orgAdmin',
          context: 'app.reviewDuplicates',
          title: 'ROUTE_TITLE.review_duplicates',
          pageDepths: { depth1: 'HQ', depth2: 'Directory', depth3: 'People', depth4: 'ReviewDuplicates' }
        },
        '/org/:currentOrgId/admins': {
          appPermission: 'adminManagement',
          context: 'app.admins',
          title: 'ROUTE_TITLE.admins',
          pageDepths: { depth1: 'HQ', depth2: 'Directory', depth3: 'Admins' }
        },
        '/org/:currentOrgId/admins/:personaId': {
          appPermission: 'adminManagement',
          context: 'app.admins.detail',
          title: 'ROUTE_TITLE.admins_detail',
          pageDepths: { depth1: 'HQ', depth2: 'Directory', depth3: 'Admins', depth4: 'ProfileSidePanel' }
        },
        '/org/oauth/callback': {
          appPermission: 'oauthCallback',
          context: 'app.oauthCallback',
          title: '',
        }
      }

      _.each(routes, function(opts, path) {
        var defaults = { reloadOnSearch: false }
        $routeProvider.when(path, _.defaults(opts, defaults))
      })
      $routeProvider.otherwise({ context: 'error.404' })

    })
    .run(function($route, $rootScope, $window, $location, $timeout, appPermission, payload, seBar) {

      function redirectRoutes(route, event) {
        var url = $location.url()

        if (
          route.params.currentOrgId &&
          payload.currentOrgId &&
          route.params.currentOrgId !== payload.currentOrgId
        ) {
          if (route.params.currentOrgId.match(/^\d+$/)) {
            $window.location.href = url
            $window.location.reload()
          }
          return true
        }
        payload.currentOrgId = route.params.currentOrgId
        var modifiedUrl = url.replace('/billing/contacts', '/contacts')
          .replace(/(\/org\/\d+)\/contacts/, '$1/people')
        if (url === modifiedUrl) return false
        $timeout(function() { $location.url(modifiedUrl).replace() })
        if (event) event.preventDefault()
        return true
      }

      function routeChangeHandler(route, event) {
        seBar.setOrgId(_.get(route, 'params.currentOrgId'))
        if (!redirectRoutes(route, event)) appPermission.checkPermissions(route)
      }

      $rootScope.$on('$routeChangeStart', function(event, next, current) {
        routeChangeHandler(next, event)
      })

      if ($route.current) routeChangeHandler($route.current)
    })

})()
