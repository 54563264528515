angular.module('org-admin')
  .directive('paymentRefund', function() {
    return {
      restrict: 'A',
      scope: {},
      bindToController: {
        sale: '=',
        refundable: '<',
        fromTransactions: '<',
        cancel: '=',
        confirm: '=',
        dialogOptions: '=',
        transactionId: '='
      },
      controllerAs: 'ctrl',
      templateUrl: '/static/org/payments/payment-refund.html',
      controller: function($q, $scope, $timeout, setAs) {
        var ctrl = this
        var refundablePayments = ctrl.sale.refundablePayments()

        ctrl.showNonRefundables = true
        ctrl.expandNonRefundablePayments = false
        ctrl.nonRefundablePayments = ctrl.sale.nonRefundablePayments()

        if (ctrl.fromTransactions) {
          ctrl.payment = _.filter(refundablePayments, { sale_transaction_id: +ctrl.transactionId })[0]
          ctrl.refundable = true
        }
        else {
          ctrl.payments = refundablePayments
        }

        $scope.$watch('ctrl.payment.id', function() {
          if (!ctrl.payment) return

          var maxIntPayment = Math.round(ctrl.payment.amount * 100)
          var maxIntRefunded = Math.round(ctrl.payment.amount_refunded * 100)

          var max = (maxIntPayment - maxIntRefunded) / 100
          ctrl.refundAmountMax = max // default to max refundAmount

          // Debounce this so that the template bindings can update for validation
          $timeout(function() { ctrl.refundAmount = max })
        })

        $scope.$watchCollection(refundablePayments, function() {
          if (ctrl.refundable && _.isEmpty(refundablePayments)) { $timeout(function() { return ctrl.cancel() }) }
        })

        // PUBLIC METHODS

        ctrl.closeNonRefundableAlert = function() {
          ctrl.showNonRefundables = false
        }

        ctrl.toggleShowMore = function() {
          ctrl.expandNonRefundablePayments = !ctrl.expandNonRefundablePayments
        }

        ctrl.refund = function() {
          if (!ctrl.payment) return

          return ctrl.payment
            .refund({ notes: ctrl.notes, amount: ctrl.refundAmount })
            .then(function(refund) {
              if (refund.status === 'failed') return $q.reject(refund)
              ctrl.confirm(refund)
            })
            .catch(setAs(ctrl, 'failure'))
        }
      }
    }
  })
