angular.module('org-admin')
  .directive('editPlanInstallment', function() {
    return {
      scope: {},
      templateUrl: '/static/org/payment-plans/edit-plan-installment.html',
      controllerAs: 'ctrl',
      bindToController: {
        amountInputFields: '<',
        amountSum: '=',
        editableInstallments: '=',
        hasUpfrontPayment: '<',
        onAmountSumUpdate: '&',
        notEditableInstallmentLength: '<'
      },

      controller: function(launchDarklyFlags) {
        var dateFormat = 'YYYY-MM-DD'
        var ctrl = this
        var installmentsCount
        var originalInstallments
        ctrl.usableCreditReason
        ctrl.noUpfrontPaymentOffset = (ctrl.hasUpfrontPayment) ? 0 : 1

        ctrl.$onInit = function() {
          ctrl.usableCreditReason = launchDarklyFlags.testingFlag
          installmentsCount = ctrl.editableInstallments.length
          originalInstallments = angular.copy(installmentsCount)
        }

        ctrl.isNewInstallment = function(index) {
          return index >= originalInstallments
        }

        ctrl.getAmountSum = function() {
          ctrl.amountSum = _.reduce(getAmounts(ctrl.editableInstallments), function(sum, num) {
            var floatSum = parseFloat(sum) || 0
            var floatNum = parseFloat(num) || 0
            return floatSum + floatNum
          })
          ctrl.onAmountSumUpdate({ amountSum: _.round(ctrl.amountSum, 2) })
        }

        ctrl.dueDatePast = function(due_date) {
          return moment(due_date).isBefore(moment(), 'day')
        }

        ctrl.removeInstallment = function(index) {
          ctrl.editableInstallments[index].amount = 0
          ctrl.getAmountSum()
          ctrl.editableInstallments.splice(index, 1)
        }

        ctrl.addInstallment = function() {
          ctrl.editableInstallments.push({
            due_date: '',
            amount: ''
          })
        }

        ctrl.getEarliestDate = function(id) {
          if (checkPristine(id)) return
          return moment().add(1, 'days').format(dateFormat)
        }
        // Private Functions
        function getAmounts(payments) {
          return _.pluck(payments, 'amount')
        }

        function checkPristine(id) {
          var datepicker = ctrl.editPlanForm['due_date_' + id]
          return datepicker ? datepicker.$pristine : false
        }
      }
    }
  })
