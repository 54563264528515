angular.module('org-admin')
  .factory('saleFilterHelper', function(getEnum, i18ng) {

    var statuses = getEnum('DISPLAY_STATUS', { returnObjectTrees: true })
    var displayStatuses = _.map(statuses, 'value') // ['open', 'paid', 'past_due', 'voided']
    var monetaryPaidStatuses = ['credited', 'paid', 'partial-refund', 'refunded']
    var monetaryOpenStatuses = ['partial-paid', 'unpaid']
    var monetaryPastDueStatus = ['past-due']
    var monetaryVoidedStatus = ['void']

    var service = {
      filterButtonLabel: filterButtonLabel,
      mapMonetaryStatuses: mapMonetaryStatuses,
      setDisplayStatus: setDisplayStatus,
      statuses: statuses
    }

    return service

    function filterButtonLabel(selectedStatuses) {
      var selectedLength = selectedStatuses.length
      if (selectedLength == statuses.length) return i18ng.t('FILTER_LABELS.status_filter_all')
      if (selectedLength < 1) return i18ng.t('FILTER_LABELS.status_filter')
      if (selectedLength == 1) return statuses[selectedStatuses[0].value]
      if (selectedLength > 1) return i18ng.t('FILTER_LABELS.status_filter_multiple')
    }

    function mapMonetaryStatuses(selectedStatuses) {
      var monetaryStatuses = []
      _.forEach(selectedStatuses, function(v, k) {
        if (v.value === displayStatuses[0]) monetaryStatuses.push(monetaryOpenStatuses)
        if (v.value === displayStatuses[1]) monetaryStatuses.push(monetaryPaidStatuses)
        if (v.value === displayStatuses[2]) monetaryStatuses.push(monetaryPastDueStatus)
        if (v.value === displayStatuses[3]) monetaryStatuses.push(monetaryVoidedStatus)
      })
      return monetaryStatuses.flat()
    }

    function setDisplayStatus(status) {
      var displayStatus = displayStatuses[-1]
      if (_.includes(displayStatuses, status)) displayStatus = status
      if (_.includes(monetaryOpenStatuses, status)) displayStatus = displayStatuses[0]
      if (_.includes(monetaryPaidStatuses, status)) displayStatus = displayStatuses[1]
      if (status === monetaryPastDueStatus[0]) displayStatus = displayStatuses[2]
      return displayStatus
    }
  })
