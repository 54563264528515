angular.module('pl-shared')

  .component('saleInfoCard', {

    bindings: {
      sale: '=',
      viewMode: '@'
    },
    templateUrl: '/static/shared/components/sales/sale-info-card.component.html',
    controllerAs: 'ctrl',
    controller: function($scope, currentOrg) {
      var ctrl = this

      $scope.$watchCollection('ctrl.sale.registers', setSaleSummary)

      ctrl.$onInit = function() {
        ctrl.currentOrg = currentOrg
        ctrl.showAdjustments = false
        ctrl.showPaymentDetail = false
        ctrl.orgView = ctrl.viewMode === 'org'
        ctrl.isInvoice = ctrl.sale.type === 'Invoice'
        ctrl.displayType = ctrl.orgView ? 'accountant' : 'endUser'
        ctrl.saleSummaryTitle = setTitleForView()
        ctrl.saleSummaryDescription = ctrl.orgView ? null : ctrl.sale.description
        ctrl.voided = ctrl.sale.display_status === 'voided'
        setSaleSummary()
        ctrl.showOutstandingAmount = ctrl.showAmountOutstanding()
        ctrl.showSubTotalAmount = ctrl.showSubTotal()
        ctrl.settledOrVoided = ['settled', 'voided'].includes(ctrl.sale.status)
      }

      function setSaleSummary() {
        ctrl.setRegisters()
        _.forEach(ctrl.sale.items, function(item) {
          item.linkable = item.item_uuid && item.name !== 'Online Processing Fee' && ctrl.orgView
        })
        ctrl.addFeeRegister()
        ctrl.addSalesTaxRegister()
      }

      ctrl.setRegisters = function() {
        ctrl.registers = ctrl.sale.registers
        ctrl.saleItemRegisters = filterRegistersByRegisterType('sale_item')
        ctrl.feeRegisters = filterRegistersByRegisterType('fee')
        ctrl.paymentRegisters = filterRegistersByRegisterType('payment')
        ctrl.creditRegisters = filterRegistersByRegisterType('credit')
        ctrl.discountRegisters = filterRegistersByRegisterType('discount')
        ctrl.salesTaxRegisters = filterRegistersByRegisterType('sales_tax')
        ctrl.subtotalRegister = findRegisterByRegisterType('subtotal')
        ctrl.totalRegister = findRegisterByRegisterType('total')
        ctrl.amountOutstandingRegister = findRegisterByRegisterType('amount_outstanding')
        ctrl.totalPaidRegister = findRegisterByRegisterType('total_paid')
        ctrl.totalCreditedRegister = findRegisterByRegisterType('total_credited')
      }

      function setTitleForView() {
        if (ctrl.isInvoice && !ctrl.orgView) { return 'Bill' }
        return ctrl.sale.type
      }

      function filterRegistersByRegisterType(registerType) {
        return _.filter(ctrl.registers, function(register) {
          return register.register_type === registerType
        })
      }

      function findRegisterByRegisterType(registerType) {
        return _.find(ctrl.registers, function(register) {
          return register.register_type === registerType
        })
      }

      ctrl.item = function(register) {
        return _.find(ctrl.sale.items, function(item) {
          return item.name === register.description
        })
      }

      ctrl.toggleShowAdjustmentsDetail = function() {
        ctrl.showAdjustments = !ctrl.showAdjustments
      }

      ctrl.toggleShowPaymentDetail = function() {
        ctrl.showPaymentDetail = !ctrl.showPaymentDetail
      }

      ctrl.showSubTotal = function() {
        return ctrl.feeRegisters.length > 0 ||
        ctrl.discountRegisters.length > 0 ||
        ctrl.salesTaxRegisters.length > 0
      }

      ctrl.showCheckoutFeeSummary = function() {
        return !ctrl.settledOrVoided &&
          ctrl.sale.customer_pays_fee &&
          !ctrl.feeRegisters.length
      }

      ctrl.addFeeRegister = function() {
        if (ctrl.showCheckoutFeeSummary()) {
          ctrl.feeRegisters.push({
            register_type: 'fee'
          })
        }
      }

      ctrl.addSalesTaxRegister = function() {
        if (ctrl.showTaxSummary()) {
          ctrl.salesTaxRegisters.push({
            register_type: 'sales_tax'
          })
        }
      }

      ctrl.showAmountOutstanding = function() {
        return ctrl.paymentRegisters.length > 0 ||
          ctrl.creditRegisters.length > 0 ||
          ctrl.voided
      }

      ctrl.showTaxSummary = function() {
        return !ctrl.settledOrVoided &&
          ctrl.sale.taxable &&
          !ctrl.salesTaxRegisters.length
      }
    }
  })
