angular.module('org-admin')

  .directive('viewPlanInstallment', function() {

    return {
      scope: {},
      bindToController: {
        currencySymbol: '<',
        fromInvoiceGroup: '<',
        fromSendInvoices: '<',
        hasUpfrontPayment: '<',
        showUpfrontPaymentHelpText: '<',
        index: '<',
        payment: '<',
        paymentMethod: '<'
      },
      templateUrl: '/static/org/payment-plans/view-plan-installment.html',
      controllerAs: 'ctrl',
      controller: function($scope, dialog) {

        var ctrl = this
        ctrl.noUpfrontPaymentOffset = (ctrl.hasUpfrontPayment) ? 0 : 1
        ctrl.installmentNumber = function() {
          return (ctrl.payment.position) ? ctrl.payment.position : ctrl.index + ctrl.noUpfrontPaymentOffset
        }

        ctrl.showAutopay = function() {
          return ctrl.paymentMethod && ctrl.payment.status == 'unpaid' && ctrl.payment.amount > 0
        }

        ctrl.originalAmountStrikethough = function() {
          return ctrl.payment.original_amount > 0 && ctrl.payment.amount != ctrl.payment.original_amount
        }

        ctrl.paymentAdjustment = function() {
          dialog.confirm({
            directive: 'payment-adjustment',
            scope: $scope,
            attrs: {
              number: ctrl.index + ctrl.noUpfrontPaymentOffset,
              payment: ctrl.payment
            }
          })
        }

      }
    }
  })
