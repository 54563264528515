angular.module('org-admin')
  .component('orderDetail', {
    templateUrl: '/static/org/orders/order-detail.component.html',
    controllerAs: 'ctrl',
    controller: function(
      $filter,
      $routeParams,
      $scope,
      $window,
      ENV,
      Alerts,
      currentOrg,
      dialog,
      i18ng,
      Order,
      Payment,
      routeTitle,
      setAs,
      launchDarklyFlags
    ) {

      var ctrl = this
      ctrl.loading = true
      ctrl.params = { org_id: currentOrg.id }
      ctrl.saleCanBePaid = false
      ctrl.someInstallmentsCanBeCancelled = false

      ctrl.$onInit = function() {
        getOrder()
        ctrl.enableCancelInstallmentsFlag = launchDarklyFlags.creditReasons
      }

      $scope.$on('order:update', getOrder)

      ctrl.sendReminder = function() {
        dialog.confirm({
          directive: 'sale-reminder',
          scope: $scope,
          attrs: {
            sales: [].concat(ctrl.order),
            allSettled: ctrl.order.is_settled
          }
        })
          .then(function(orders) {
            orders.length > 1 ? Alerts.success(orders.length + i18ng.t('SALE_REMINDER.success_plural')) :
              Alerts.success(i18ng.t('SALE_REMINDER.success'))
          })
      }

      ctrl.refundPayment = function() {
        dialog.confirm({
          directive: 'payment-refund',
          scope: $scope,
          attrs: {
            sale: ctrl.order,
            refundable: _.any(ctrl.order.payments, { can_be_refunded: true })
          }
        })
          .then(function(refund) {
            Alerts.success(i18ng.t('PAYMENT_REFUND.success', { refund_amount: $filter('currency')(refund.amount) }))
            $scope.$emit('order:update')
          })
      }

      ctrl.payOffline = function() {
        dialog.confirm({
          directive: 'pay-offline',
          scope: $scope,
          attrs: {
            invoice: ctrl.order
          }
        })
          .then(function(payment) {
            Alerts.success(i18ng.t('PAY_OFFLINE.success', { payment_amount: $filter('currency')(payment.amount) }))
            $scope.$emit('order:update')
          })
      }

      ctrl.onlinePayment = function() {
        $window.open(ENV.urls.sePayments + '/' + ctrl.order.sale_number)
      }

      ctrl.applyCredit = function() {
        dialog.confirm({
          directive: 'invoice-apply-credit',
          scope: $scope,
          attrs: {
            invoice: ctrl.order
          }
        })
          .then(function(credit) {
            ctrl.loadPayments()
            Alerts.success(i18ng.t('INVOICE_APPLY_CREDIT.success', { credit_amount: $filter('currency')(credit[0].amount) }))
            $scope.$emit('order:update')
          })
      }

      ctrl.cancelRemainingInstallments = function() {
        dialog.confirm({
          directive: 'cancel-payment-plan',
          scope: $scope,
          attrs: {
            sale: ctrl.order,
            persistedPayments: ctrl.order.payments,
          }
        })
          .then(function(credits) {
            getOrder().then(() => {
              Alerts.success(i18ng.t('INVOICE_APPLY_CREDIT.success', {
                credit_amount: $filter('currency')(credits.reduce((partialSum, credit) => {
                  return partialSum + parseFloat(credit.amount)
                }, 0))
              }))
            })
          })
      }

      ctrl.voidOrder = function() {
        dialog.confirm({
          directive: 'invoice-void',
          scope: $scope,
          attrs: {
            invoice: ctrl.order
          }
        })
          .then(function(saved) {
            ctrl.invoice = saved
            Alerts.success('INVOICE_VOID.success', { sale_number: saved.sale_number })
            $scope.$emit('order:void', saved)
            $scope.$emit('order:update')
          })
      }

      ctrl.loadPayments = function() {
        return Payment.adjustmentCountsByDueDate(ctrl.order.id)
          .then(setAs(ctrl, 'payments'))
      }

      ctrl.print = function() {
        var app = document.getElementById('App')
        document.body.insertBefore(document.getElementsByClassName('order-detail-print')[0], app)
        app.classList.add('order-detail-printing')
        window.addEventListener('afterprint', printCleanup)
        setTimeout(window.print)
      }

      function printCleanup() {
        var app = document.getElementById('App')
        var node = document.getElementsByClassName('order-detail-print')[0]
        var container = document.getElementsByTagName('order-detail-print')[0]
        app.classList.remove('order-detail-printing')
        container.append(node)
        window.removeEventListener('afterprint', printCleanup)
      }

      function getOrder() {
        var id = $routeParams.orderId
        if (!id) return
        ctrl.loading = true
        return Order.find(id, { bypassCache: true })
          .then(function(response) {
            ctrl.order = response
            ctrl.monetaryOrder = Number(ctrl.order.total_unadjusted)
            ctrl.survey = ctrl.order.surveySource()
            routeTitle.setParams({
              sale_number: ctrl.order.sale_number
            })
            ctrl.showRefundButton = anyPaid() || anyChargedback()
            ctrl.saleCanBePaid = ctrl.order.canBePaid()
            if (ctrl.order.amount_outstanding === '0.00' && (_.some(ctrl.order.refunds, { status: 'failed' }) ||
                _.some(ctrl.order.refunds, { status: 'requested' })) &&
                !_.some(ctrl.order.refunds, { status: 'partial' }) &&
                !_.some(ctrl.order.refunds, { status: 'approved' })) {
              ctrl.order.monetary_status = 'paid'
            }
          })
          .then(ctrl.loadPayments)
          .then(() => {
            ctrl.someInstallmentsCanBeCancelled = getCancellableInstallments().length > 0
            return loadActivity()
          })
          .finally(() => {
            ctrl.loading = false
          })
      }

      function anyPaid() {
        return _.any(ctrl.order.payments, { is_paid: true })
      }

      function anyChargedback() {
        return _.any(ctrl.order.payments, { is_chargeback: true })
      }

      function loadActivity() {
        return ctrl.order.significantActivity()
          .then(setAs(ctrl, 'activity'))
      }

      function isLockedPaymentStatus(payment) {
        return payment.status === 'canceled' || payment.status === 'disputed' || payment.status === 'chargeback'
      }

      function getCancellableInstallments() {
        return _.filter(ctrl.order.payments, function(payment) {
          if (isLockedPaymentStatus(payment)) return false
          return !payment.is_paid && !payment.upfront_payment
        })
      }
    }
  })
